const primary = '#282840';
const secondary = '#60BEB3';
const bgColor = '#ffffff';

const baseColors = {
  primary,
  secondary,
  red: '#dc3545',
  primaryBg: bgColor,

  // datatable
  bgDatatable: bgColor,
  colorDatatable: 'null',

  // select
  bgSelect: bgColor,
  bgMultiValueSelect: secondary,
  hoverBgMultiValueRemoveSelect: secondary,
  colorMultiValueSelect: primary,
  hoverTextMultiValueRemoveSelect: bgColor,

  // text points product
  colorTextPointsProducts: primary,

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
